import React from 'react';
import { graphql } from 'gatsby';

import { useTrackedState } from '../lib/state';

import SubLayout from '../components/SubLayout';
import AnnouncementsArea from '../components/AnnouncementsArea';

const Annoucements = ({ data: { allMarkdownRemark } }) => {
  const announcements = allMarkdownRemark.edges
    .filter(edge => !!edge.node.frontmatter.date)
    .map(({ node: { id, excerpt, frontmatter: { date, title, path } } }) => ({
      id,
      excerpt,
      date,
      title,
      path,
    }));
  const { size } = useTrackedState();
  return (
    <SubLayout topTitle="おしらせ">
      <div
        css={`
          margin: 20px;
        `}
      >
        <AnnouncementsArea data={announcements} size={size} />
      </div>
    </SubLayout>
  );
};
export default Annoucements;

export const pageQuery = graphql`
  query {
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { fileAbsolutePath: { regex: "/announcements/" } }
    ) {
      edges {
        node {
          id
          excerpt(pruneLength: 500)
          frontmatter {
            date(formatString: "YYYY/MM/DD")
            title
            path
          }
        }
      }
    }
  }
`;
